export default class Site {
	
	constructor () {
		this.header = document.getElementById("siteHeader");
		if (!this.header.classList.contains("minimal")) {
			this.headerStickyPolyfill();
			document.addEventListener("scroll", this.onScroll);
		}
		
		this.toggleNav = document.getElementById("toggleNav");
		this.toggleNav.addEventListener("click", e => {
			e.preventDefault();
			document.body.classList.toggle("nav-open");
		});
		
		if (!Object.entries) {
			Object.entries = function (obj) {
				let ownProps = Object.keys(obj),
					i = ownProps.length,
					resArray = new Array(i); // preallocate the Array
				while (i--)
					resArray[i] = [ownProps[i], obj[ownProps[i]]];
				
				return resArray;
			};
		}
	}
	
	// Browser Sniffing
	// =========================================================================
	
	static sniff () {
		const browser = Site.getBrowserName();
		if (browser)
			document.body.classList.add(browser);
		
		if (window.devicePixelRatio === 2)
			document.body.classList.add("retina");
		
		if (navigator.platform.match("Mac") !== null)
			document.body.classList.add("macos");
	}
	
	// Header
	// =========================================================================
	
	onScroll = () => {
		if (window.scrollY >= 90) this.header.classList.add("sticky");
		else this.header.classList.remove("sticky");
	};
	
	headerStickyPolyfill () {
		if (!window.hasOwnProperty("CSS") || !CSS.supports("position", "sticky")) {
			this.header.classList.add("sticky-fallback");
			const placeholder = document.createElement("div");
			placeholder.classList.add("site-header--placeholder");
			this.header.parentNode.insertBefore(
				placeholder,
				this.header
			);
		}
	}
	
	// Helpers
	// =========================================================================
	
	/* eslint-disable */
	static getBrowserName () {
		const ua = navigator.userAgent;
		
		// Opera 8.0+
		if (
			(!!window.opr && !!opr.addons)
			|| !!window.opera
			|| ua.indexOf(' OPR/') >= 0
		) return "is-opera";
		
		// Firefox 1.0+
		if (typeof InstallTrigger !== 'undefined')
			return "is-firefox";
		
		// Safari 3.0+ "[object HTMLElementConstructor]"
		if (
			/constructor/i.test(window.HTMLElement)
			|| (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari']
			|| (typeof safari !== 'undefined' && safari.pushNotification))
		) return "is-safari";
		
		// Internet Explorer 6-11
		const isIE = /*@cc_on!@*/false || !!document.documentMode;
		if (isIE) return "is-ie";
		
		// Edge 20+
		if (!isIE && !!window.StyleMedia)
			return "is-edge";
		
		// Chrome 1+
		if (!!window.chrome && !!window.chrome.webstore)
			return "is-chrome";
		
		// iPad
		if (
			ua.match(/iPad/i) !== null
		    || /iPad/i.test(ua)
		    || /iPhone OS 3_1_2/i.test(ua)
		    || /iPhone OS 3_2_2/i.test(ua)
		) return "is-ipad";
		
		return "";
	}
	/* eslint-enable */
	
}
